import { EnvironmentType } from './environment.type';

export const environment: EnvironmentType = {
  sentry: {
    environment: 'test',
    tracingOrigin: 'test.coach.trivs.app'
  },
  enableStoreDevTools: false,
  enableAnalytics: false
};
